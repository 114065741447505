import React, { FC, useState } from 'react';
import { Button, Modal } from 'antd';

import { useLevelDataConfigContext } from '@pages/map-level/context';
import { GridType } from '@pages/map-level/utils/enumeration';
import { useGlobalObjectCollectionContext } from '@providers/object-collection';

import ButtonLayer from '../button-layer';
import ButtonStage from '../button-stages';
import GridLayout from '../grid-layout';

const LayoutGrid: FC<{ viewOnly?: boolean }> = ({ viewOnly }) => {
  const {
    clearLayer,
    clearStage,
    stages,
    stageSelected,
    setStage,
    tileGlobal,
    gridType,
    layerSelected,
    _setLayerSelected,
    maxLayer,
  } = useLevelDataConfigContext();
  const { tileMap } = useGlobalObjectCollectionContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [target, setTarget] = useState('');

  const onOk = () => {
    setIsModalOpen(false);
    if (target === 'layer') clearLayer();
    else clearStage();
  };

  const Confirm: FC = () => {
    return (
      <Modal
        title="Confirm clear"
        visible={isModalOpen}
        onOk={onOk}
        onCancel={() => setIsModalOpen(false)}
        okText="Clear"
        cancelText="Cancel"
        okButtonProps={{ type: 'primary', danger: true }}
      >
        Are you sure to clear all items on this {target}?
      </Modal>
    );
  };

  function RenderButtons() {
    const buttonList = Array.from({ length: stages }, (_, index) => index + 1);

    return (
      <div className="flex flex-col items-center justify-center">
        {buttonList.map((stage, i) => (
          <ButtonStage
            key={i}
            onClickStageButton={setStage}
            stageNumber={stage}
            curStageNumber={stageSelected}
          />
        ))}
      </div>
    );
  }

  function RenderLayersButtons() {
    const buttonList = Array.from({ length: maxLayer }, (_, index) => index + 1);

    return (
      <div className="flex items-center justify-center">
        <ButtonLayer
          // key={4}
          onClickLayerButton={_setLayerSelected}
          layerNumber={4}
          curLayerNumber={layerSelected}
        />
        {buttonList.slice(0, -1).map((layer, i) => (
          <ButtonLayer
            key={i}
            onClickLayerButton={_setLayerSelected}
            layerNumber={layer}
            curLayerNumber={layerSelected}
          />
        ))}
      </div>
    );
  }

  return (
    <>
      <Confirm />
      <div className="flex mb-3 mr-4">
        <div className="ml-auto flex justify-center items-center">
          <span>Layers: </span>
          <RenderLayersButtons />

          {!viewOnly && (
            <Button
              style={{ height: 54 }}
              onClick={() => {
                setTarget('layer');
                setIsModalOpen(true);
              }}
              className="ml-3 text-center"
              danger
            >
              CLEAR <br /> LAYER
            </Button>
          )}
        </div>
      </div>

      <div className="flex" style={{ height: '500px', overflowY: 'scroll' }}>
        {gridType === GridType.Segment && (
          <div className="flex flex-col mr-3 items-center justify-center">
            <span className="mb-3">Stages: </span>
            <div className="flex flex-col items-center justify-center">
              <RenderButtons />
              {!viewOnly && (
                <Button
                  style={{ height: 54 }}
                  onClick={() => {
                    setTarget('stage');
                    setIsModalOpen(true);
                  }}
                  className="mt-3 text-center"
                  danger
                >
                  CLEAR <br /> STAGE
                </Button>
              )}
            </div>
          </div>
        )}
        <div style={{ marginLeft: gridType === GridType.Segment ? 48 : 136 }}>
          <GridLayout tile={tileMap[tileGlobal]} layer={layerSelected} viewOnly={viewOnly} />
        </div>
      </div>
    </>
  );
};

export default LayoutGrid;
